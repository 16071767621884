<template>
  <div>
    <a class="link legal text-sm" href="/legal/privacy">Privacy Policy</a>&nbsp;|&nbsp;
    <a class="link legal text-sm" href="/legal/user-terms-of-service">Terms</a>&nbsp;|&nbsp;
    <a class="link legal text-sm" href="/legal">Legal</a>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="stylus" scoped>
.link
  @apply text-gray-300 hover_text-white hover_opacity-50

  &.legal
    @apply text-white
</style>
