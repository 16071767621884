<template>
  <footer class="bg-gray-900 text-white">
    <div class="container py-12">
      <div class="flex flex-col justify-center items-center mb-8">
        <NuxtLink :to="`${config.public.WEB_ENDPOINT}`">
          <MarkLogo class="w-10 h-10" />
        </NuxtLink>
      </div>
      <div class="font-light flex justify-between">
        <FooterLegalText v-if="showLegal" />
        <div v-else />
        <p class="text-xs">© {{ new Date().getFullYear() }} VRIFY Technology, Inc.&nbsp;<br class="lg_hidden" />All rights reserved</p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import MarkLogo from '~/assets/images/mark-logo.svg'
const config = useRuntimeConfig()

defineProps({
  showLegal: {
    type: Boolean,
    default: true,
  },
})
</script>
